<div class="modal-header">
  <h4 class="modal-title">Upload Transactions</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="alert alert-warning alert-line-height" role="alert">
    Please verify the column format by downloading the sample template. <br />
    Date format should be <strong>YYYY-MM-DD</strong><br />
    Description should be <strong>Goods</strong> or <strong>Services</strong> or <strong>Goods &amp; Services</strong> or <strong>Software</strong>
  </div>
  <div class="alert alert-success" role="alert" *ngIf="processingExcel"> 
    <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon> Processing excel file. Please Wait...
  </div> 
  <div class="form-row" *ngIf="!processingExcel">
    <div class="form-group col-md-12">
      <div class="custom-file">
        <input type="file" class="custom-file-input" (change)="onFileChange($event)">
        <label class="custom-file-label" for="Upload Excel">Upload Excel</label>
      </div>
    </div>
  </div>
  <div class="form-row" *ngIf="processingExcel">
</div>
