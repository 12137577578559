import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DciAuthModule, AuthService } from '@dell/dci-authentication';
import { HomeComponent } from './components/home/home.component';
import { NgbModule, NgbDatepickerModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ReportComponent } from './components/report/report.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { IndexComponent } from './components/index/index.component';

import { ViewInvoiceComponent } from './modal/view-invoice/view-invoice.component';
import { UploadTemplateComponent } from './modal/upload-template/upload-template.component';
import { AppconfigService } from './shared/appconfig.service';
import { NgbDateCustomParserFormatter } from './shared/ngb-date-custom-parser-formatter.service';
import { NgbDateCustomAdapter } from './shared/ngb-date-custom-adapter.service';
import { AuthInterceptorService } from './helpers/auth-interceptor.service';
import { ErrorInterceptorService } from './helpers/error-interceptor.service';

import { SumPipe } from './pipe/sum.pipe';
import { DuplicateWarningComponent } from './modal/duplicate-warning/duplicate-warning.component';
import {forge} from 'node-forge';

export function initializerFn(appconfigService: AppconfigService) {
  return () => {
    return appconfigService.loadAppConfig();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ReportComponent,
    InvoiceComponent,
    IndexComponent,
    UploadTemplateComponent,
    ViewInvoiceComponent,
    SumPipe,
    DuplicateWarningComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DciAuthModule,
    NgbModule,    
    HttpClientModule,
    NgbDatepickerModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule
  ],
  providers: [AuthService, NgbDateCustomParserFormatter, NgbDateCustomAdapter, NgbActiveModal,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppconfigService],
      useFactory: initializerFn
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
