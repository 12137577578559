import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from '../../services/report/report.service';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {

  transactions: any;
  quarter: any;
  invoices: any;
  displayQuarter = false;
  @Input() public vendor: any;

  constructor(public activeModal: NgbActiveModal, private reportService: ReportService) { }

  ngOnInit(): void {
    if (this.vendor !== undefined) {
      this.getInvoices(this.vendor['id']);
    }
  }

  getInvoices(vendorId: any) {
    this.reportService.getInvoices(vendorId).subscribe((result) => {
      this.invoices = result;
    });
  }

}
