import { Component, OnInit } from '@angular/core';
import { faFile, faUpload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  faFile = faFile;
  faUpload = faUpload;

  constructor() { }

  ngOnInit(): void { }

}
