<script>
    if (global === undefined) {
      var global = window;
    }
  </script>
<div class="container">
    <div class="card text-center card-login" >
        <div class="card-body">
            <img [src]="imageSrc" [alt]="imageAlt" width="75" height="75" />
            <h1 class="h3 font-weight-normal">Tax Information Collection System</h1>           
            <button class="btn btn-lg btn-outline-light" type="submit" (click)="dciLogin()">Click here to login&nbsp;<fa-icon [icon]="faSignInAlt"></fa-icon></button>
        </div>
    </div>
</div>