import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@dell/dci-authentication';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  keySize = 128 / 32;
  iterationCount = 1000;


  constructor(public authService: AuthService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.checkIsAuthenticated()) {
      let access_token: string = sessionStorage.getItem('access_token')!;
      let userId : string = sessionStorage.getItem('userId')!;

    
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + access_token
    
        }
      });
      console.log(request.url);
    }
    return next.handle(request);
  }

 
}
