import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppconfigService } from '../../shared/appconfig.service';



@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  apiBaseUrl: string | undefined;
  encrypted = null;

  constructor(private httpClient: HttpClient, private appconfigService: AppconfigService) {
    this.apiBaseUrl = this.appconfigService.apiBaseUrl
    //this.encrypted = this.encrypt1(sessionStorage.getItem('userId'));
  }

  //Get Quarters from backend
  getQuarters() {
    return this.httpClient.post(this.apiBaseUrl + "/portal/magicQucik?xxPara2=Y",'');
  }

  //Check Duplicate
  async checkDuplicate(quarterId: any, userId: any) {
    return this.httpClient.post(this.apiBaseUrl + '/portal/downloadXX/zzz123MMn?'+ 'xx123=' + btoa(quarterId),'').toPromise();
  }

  //Get Invoice details
  async getInvoices(vendorId: any) {
    return this.httpClient.post(this.apiBaseUrl + '/portal/checkXXX$$$12/xxWWW?xxId=' + btoa(vendorId),'').toPromise();
  }

  //Add Vendor
  async addVendor(vendor: any) {
    return this.httpClient.post(this.apiBaseUrl + '/portal/downloadXX/xxQQQ', vendor).toPromise();
  }

  //Update Vendor
  async updateVendor(vendor: any) {
    return this.httpClient.patch(this.apiBaseUrl + '/portal/downloadXX/' +btoa( vendor.id), vendor).toPromise();  
  }

  //Add Invoices
  async addInvoice(lineItems: any) {
    //Append Vendor ID for each invoice item
    return this.httpClient.post(this.apiBaseUrl + '/portal/checkXXX$$$12', lineItems).toPromise()
  }

  //Update Invoices   
  async updateInvoice(lineItems: any) {
    return this.httpClient.patch(this.apiBaseUrl + '/portal/checkXXX$$$12', lineItems).toPromise();
  }

  //Delete Invoices
  async deleteInvoice(lineItem: any) {
    return this.httpClient.delete(this.apiBaseUrl + '/portal/checkXXX$$$12/' + lineItem).toPromise();
  }

  //Upload TcsCertificate
  async uploadTcsCertificate(file: any, vendorId: any, quarterId: any) {
    const formData = new FormData();
    formData.append("xx12", file, file.name);
    formData.append("yy567", btoa(vendorId));
    formData.append("zzswe34", btoa(quarterId));
    return this.httpClient.post(this.apiBaseUrl + '/portal/welding', formData).toPromise();
  }

  //Update TcsCertificate
  async updateTcsCertificate(file: any, fileId: any, vendorId: any, quarterId: any) {
    const formData = new FormData();
    formData.append("wed12", file, file.name);
    formData.append("yy567", btoa(vendorId));
    formData.append("zzswe34", btoa(quarterId));
    return this.httpClient.put(this.apiBaseUrl + '/portal/welding/' + btoa(fileId), formData).toPromise();
  }

   checkFileContent(file: any) {
    const formData = new FormData();
    formData.append("xx156", file, file.name);
    return this.httpClient.post(this.apiBaseUrl + '/portal/welding/xx345', formData);
  }


  //Get TcsCertificate
  async getTcsCertificate(vendorId: any) {
    return this.httpClient.post(this.apiBaseUrl + '/portal/welding/xx12?xx000=' + btoa(vendorId),'').toPromise();
  }

  //Download TcsCertificate
  downloadTcsCertificate(fileId: any) {
    return this.httpClient.post(this.apiBaseUrl + '/portal/welding/' + btoa(fileId), { responseType: 'blob' })
  }

  getQuarterById(quarterId: any) {
    return this.httpClient.post(this.apiBaseUrl + '/portal/magicQucik/' + btoa(quarterId),'');
  }

}
