import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppconfigService {

  private appConfig: any;
  data :string | undefined;

  constructor(private http: HttpClient) {
    this.data = environment.apiBaseUrl;
    
   }

  async loadAppConfig() {
    console.log("data"+this.data);
  
    this.appConfig = this.data;
  }
  get apiBaseUrl() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig;
  }
}