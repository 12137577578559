import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ReportComponent } from './components/report/report.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { IndexComponent } from './components/index/index.component';
import {
  AuthGuardService as AuthGuard
} from './helpers/auth-guard.service';

const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: '*', component: IndexComponent ,canActivate: [AuthGuard]},
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'report', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'invoice', component: InvoiceComponent, canActivate: [AuthGuard] },


  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
