import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceService } from '../../services/invoice/invoice.service';

@Component({
  selector: 'app-duplicate-warning',
  templateUrl: './duplicate-warning.component.html',
  styleUrls: ['./duplicate-warning.component.css']
})
export class DuplicateWarningComponent implements OnInit {

  @Input() public quarterId: any;
  quarter: any;
  quarterDisplay = false;

  constructor(public activeModal: NgbActiveModal, private invoiceService: InvoiceService) { }

  @Output() passUserSelection: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.quarterId !== undefined) {
      this.getQuarterById(this.quarterId);
    }
  }

  getQuarterById(quarterId: any) {
    this.invoiceService.getQuarterById(quarterId).subscribe((result) => {
      this.quarter = result;
      this.quarterDisplay = true;
    });
  }

  userAction(choice: any) {
    this.passUserSelection.emit({ data: choice, res: 200 });
    this.activeModal.dismiss('Cross click')
  }

}
