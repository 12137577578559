import { Component, OnInit } from '@angular/core';
import { faEye, faFilePdf, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from '../../services/report/report.service';
import { ViewInvoiceComponent } from '../../modal/view-invoice/view-invoice.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  faEye = faEye;
  faFilePdf = faFilePdf;
  faCircleNotch = faCircleNotch;
  vendors: any;
  quarters: any;
  dataLoading = false;
  term: any = null;
  itemDownloading: any;

  constructor(private reportService: ReportService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getVendorsByUserId();
    this.getQuarters();
  }

  //Get list of quarters
  getQuarters() {
    this.reportService.getQuarters().subscribe((result) => {
      this.quarters = result;
    });
  }

  getVendorsByUserId() {
    this.dataLoading = true;
    let userId: string = sessionStorage.getItem('userId')!;
    this.reportService.getVendorsByUserId(userId).subscribe((result) => {
      this.vendors = result;
      this.dataLoading = false;
    });
  }

  viewInvoice(vendor: any) {
    const modalRef = this.modalService.open(ViewInvoiceComponent, { size: 'xl' });
    modalRef.componentInstance.vendor = vendor;
  }

  downloadVendorTcsCertificate(vendorId: any, vendorName: any) {
    this.itemDownloading = vendorId;
    this.reportService.downloadVendorTcsCertificate(vendorId).subscribe((response: any) => {
      console.log(response);
      let blob: any = new Blob([response], { type: 'application/pdf' });
      saveAs(blob, vendorName + '.pdf');
      this.itemDownloading = null;
    }, () => { this.itemDownloading = null; });
  }

}
