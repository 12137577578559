import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
//import * as XLSX from 'xlsx';

@Component({
  selector: 'app-upload-template',
  templateUrl: './upload-template.component.html',
  styleUrls: ['./upload-template.component.css']
})
export class UploadTemplateComponent implements OnInit {

  faCircleNotch = faCircleNotch;
  processingExcel = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) { }

  @Output() passExcelData: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void { }

  onFileChange(evt: any) {
  
    this.activeModal.dismiss('Cross click')
  }
}
