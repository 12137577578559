import { Injectable } from '@angular/core';
import { AuthService } from '@dell/dci-authentication';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class AuthGuardService implements CanActivate {
  http: any;
  constructor(public authService: AuthService, public router: Router) { }
  canActivate(): boolean {
    if (this.authService.checkIsAuthenticated()) {

     
      return true;
    }else{
      this.authService.Redirect_After_loginlogout = '';
      this.router.navigate(["/endsession"]);
     
    return false;
    }
   
  }





}

 

   



