<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Duplicate Warning</h4>
</div>
<div class="modal-body">
    <p><span *ngIf="quarterDisplay"><strong>
        {{quarter.year}} - {{quarter.quarter}}</strong></span> TCS data already submitted please confirm if you want to resubmit the data</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="userAction('cancel')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="userAction('ok')">Ok</button>
</div>