<div class="modal-header">
    <h4 class="modal-title">View Invoice Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-row">       
        <div class="form-group col-md-6">
            <label for="quarter">Quarter</label>
            <div class="form-control">{{ vendor['quarter']}}</div>
        </div>
        <div class="form-group col-md-6">
            <label for="certificateNumber">Name as per PAN</label>
            <div class="form-control">{{ vendor['name'] }}</div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <label for="quarter">PAN</label>
            <div class="form-control">{{ vendor['pan'] }}</div>
        </div>
        <div class="form-group col-md-4">
            <label for="certificateNumber">TAN</label>
            <div class="form-control">{{ vendor['tan'] }}</div>
        </div>
        <div class="form-group col-md-4">
            <label for="quarter">GSTIN</label>
            <div class="form-control">{{ vendor['gstin'] }}</div>
        </div>
    </div>
    <div class="form-row no-margin-bottom">
        <div class="form-group col-md-12 no-margin-bottom">
            <table class="table table-striped table-hover no-margin-bottom" aria-describedby="line items table">
                <thead class="thead-light">
                    <tr>
                        <th>TAN Number</th>
                        <th>Invoice Number</th>
                        <th>CN/DN Number</th>
                        <th>Prepayment (advance) details</th>
                        <th>Invoice Date</th>
                        <th>Invoice Type</th>
                        <th>Invoice Amount</th>
                        <th>CGST</th>
                        <th>SGST</th>
                        <th>IGST</th>
                        <th>TCS on invoice to Dell</th>
                        <th>TCS amount remitted to Govt</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of invoices">
                        <td>{{item.tan}}</td>
                        <td>{{item.invoiceNumber}}</td>
                        <td>{{item.cnDnNumber}}</td>
                        <td>{{item.prepaymentDetail}}</td>
                        <td>{{item.invoiceDate}}</td>
                        <td>{{item.invoiceType}}</td>
                        <td>{{item.invoiceAmount}}</td>
                        <td>{{item.cgst}}</td>
                        <td>{{item.sgst}}</td>
                        <td>{{item.igst}}</td>
                        <td>{{item.tcsCharged}}</td>
                        <td>{{item.tcsDeposited}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="6">Total</th>
                        <th>  {{invoices | sum:'invoiceAmount'}} </th>
                        <th>{{invoices | sum:'cgst'}}</th>
                        <th>{{invoices | sum:'sgst'}}</th>
                        <th>{{invoices | sum:'igst' }}</th>
                        <th>{{invoices | sum:'tcsCharged'  }}</th>
                        <th>{{invoices | sum:'tcsDeposited' }}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>