import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService, AuthCodeInput } from '@dell/dci-authentication';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  imageSrc = '../assets/images/Dell_Logo_Wht_rgb.png'
  imageAlt = 'Logo'
  faSignInAlt = faSignInAlt
  clientId = '';

  constructor(public authService: AuthService, private router: Router) {
    this.clientId = environment.client_id;
   } 

  ngOnInit(): void {
    if (this.authService.checkIsAuthenticated()) {
      this.router.navigate(['home']);
    } 
  }

  dciLogin() {
    var AuthInput = new AuthCodeInput();
    console.log("id"+this.clientId);
    AuthInput.client_id = this.clientId;
    
    //AuthInput.discoveryUrl = 'https://www-sit-g1.dell.com/di/v3/fp/oidc/v3/.well-known/openid-configuration';
    AuthInput.discoveryUrl = 'https://www.dell.com/dci/fp/v3/.well-known/openid-configuration';
     AuthInput.scope = "openid dellprofile";
    AuthInput.state = "authCheck123";
    this.authService.AuthInput = AuthInput;
   this.authService.Redirect_After_loginlogout = '/home';
    //this.authService.Redirect_After_loginlogout = 'https://tics.dell.com/callback';

   console.log( "getDiscoveryDetails"+this.authService.getDiscoveryDetails);
    this.router.navigate(["/authenticate"]);
  }

}
