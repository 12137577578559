<div class="pricing-header px-3 pt-3 mx-auto text-center">
    <h3 class="display-5">TICS</h3>
    <p class="lead">Tax Information Collection System</p>
</div>

<div class="container-fluid">
    <form [formGroup]="invoiceForm" (ngSubmit)="publishInvoice()">
        <div class="card-deck mb-3 text-center" id="alert-section">
            <div class="card border-primary-top mb-4 box-shadow">
                <div class="card-body">
                    <ngb-alert [dismissible]="false" type="success" *ngIf="duplicateChecking">
                        Checking for duplicate data. Please wait... <fa-icon [icon]="faCircleNotch" [spin]="true">
                        </fa-icon>
                    </ngb-alert>  
                    
                
                    <ngb-alert [dismissible]="false" type="success" *ngIf="dataSaved">
                        {{ successMessage }}
                    </ngb-alert>
                    <ngb-alert [dismissible]="false" type="danger" *ngIf="errorOccurred">
                        <ul class="list-unstyled mb-0 mt-0">
                            <li *ngFor="let erm of errorMessage">{{ erm }}</li>
                        </ul>
                    </ngb-alert>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="quarter">Quarter</label>
                            <select class="form-control" formControlName="quarterId" (change)='checkForDuplicate()'>
                                <option *ngFor="let quarter of quarters" [value]="quarter.id">{{quarter.year}} -
                                    {{quarter.quarter}}</option>
                            </select>
                            <span class="text-danger"
                                *ngIf="(invoiceFormControl.quarterId.touched || submitted) && invoiceFormControl.quarterId.errors?.required">
                                Quarter is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-deck mb-3 text-center" *ngIf="addInvoice">
            <div class="card border-primary-top mb-4 box-shadow">
                <div class="card-header">
                    <h5 class="my-0 font-weight-normal">Please provide the below details</h5>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="vendorName">Name</label>
                            <input type="name" formControlName="name" class="form-control"
                                placeholder="Name">
                            <span class="text-danger"
                                *ngIf="(invoiceFormControl.name.touched || submitted) && invoiceFormControl.name.errors?.required">
                                Name is required
                            </span>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="vendorPan">PAN</label>
                            <input type="text" formControlName="pan" class="form-control" placeholder="PAN" value="">
                            <span class="text-danger"
                                *ngIf="(invoiceFormControl.pan.touched || submitted) && invoiceFormControl.pan.errors?.required">
                                PAN is required
                            </span>
                            <span class="text-danger"
                                *ngIf="(invoiceFormControl.pan.touched || submitted) && invoiceFormControl.pan.errors?.pattern">
                                PAN not valid.
                            </span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="vendorTan">TAN</label>
                            <input type="text" formControlName="tan" class="form-control" placeholder="TAN">
                            <span class="text-danger"
                                *ngIf="(invoiceFormControl.tan.touched || submitted) && invoiceFormControl.tan.errors?.required">
                                TAN is required
                            </span>
                            <span class="text-danger"
                                *ngIf="(invoiceFormControl.tan.touched || submitted) && invoiceFormControl.tan.errors?.pattern">
                                TAN not valid.
                            </span>
                        </div>
                        <div class="form-group col-md-5">
                            <label for="vendorGstin">GSTIN</label>
                            <input type="text" formControlName="gstin" class="form-control" placeholder="GSTIN"
                                [disabled]='gstinDisabled'>
                            <span class="text-danger"
                                *ngIf="(invoiceFormControl.gstin.touched || submitted) && invoiceFormControl.gstin.errors?.required">
                                GSTIN is required
                            </span>
                            <span class="text-danger"
                                *ngIf="(invoiceFormControl.gstin.touched || submitted) && invoiceFormControl.gstin.errors?.pattern">
                                GSTIN not valid.
                            </span>
                        </div>
                        <div class="form-group col-md-1 text-left pt-4">
                            <div class="form-check">
                                <input type="checkbox" formControlName="unregGstin" class="form-check-input"
                                    (change)="changeGstin($event)">
                                <label for="unregGstin">UNREG</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-deck mb-3 text-center" *ngIf="addInvoice">
            <div class="card border-primary-top mb-4 box-shadow">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8 text-left">
                            <h5 class="my-0 font-weight-normal text-left">Transaction Details</h5>
                        </div>
                        <div class="col-md-4">
                            <div class="float-right">
                                <a [href]="templateUrl" [download]="templateFileName"
                                    class="btn btn-outline-dark btn-sm mr-2">Download Template
                                </a>
                                <button class="btn btn-outline-dark btn-sm"
                                    (click)="openUploadTemplate()">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-row no-margin-bottom table-fixed ">
                        <div class="form-group col-md-12 no-margin-bottom" >
                            <table class="table table-striped table-hover no-margin-bottom  "
                                aria-describedby="line items table">
                                <thead class="thead-light">
                                    <tr>
                                        <th>TAN Number</th>
                                        <th>Invoice Number</th>
                                        <th>CN/DN Number</th>
                                        <th>Prepayment (advance) details</th>
                                        <th>Invoice Date</th>
                                        <th>Invoice Type</th>
                                        <th>Invoice Amount</th>
                                        <th>IGST</th>
                                        <th>CGST</th>
                                        <th>SGST</th>
                                        <th>TCS on invoice to Dell</th>
                                        <th>TCS amount remitted to Govt</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container formArrayName="lineItems">
                                        <tr *ngFor="let item of getLineItems(); let i = index;" [formGroupName]="i">
                                            <td><input type="text" formControlName="tan" class="form-control inputForm1" />
                                                <span class="text-danger"
                                                    *ngIf="(item['controls'].tan.touched || submitted) && item['controls'].tan?.errors?.required">
                                                    Required
                                                </span>
                                                <span class="text-danger"
                                                 *ngIf="(item['controls'].tan.touched || submitted) && item['controls'].tan.errors?.pattern">
                                                 TAN not valid.
                                                </span>
                                            </td>
                                            <td><input type="text" formControlName="invoiceNumber"
                                                    class="form-control inputForm1" />
                                                <span class="text-danger"
                                                    *ngIf="(item['controls'].invoiceNumber.touched || submitted) && item['controls'].invoiceNumber?.errors?.required">
                                                    Required
                                                </span>
                                            </td>
                                            <td><input type="text" formControlName="cnDnNumber" class="form-control inputForm1" />
                                                <span class="text-danger"
                                                    *ngIf="(item['controls'].cnDnNumber.touched || submitted) && item['controls'].cnDnNumber?.errors?.required">
                                                    Required
                                                </span>
                                            </td>
                                            <td><input type="text" formControlName="prepaymentDetail"
                                                    class="form-control inputForm1" />
                                                <span class="text-danger"
                                                    *ngIf="(item['controls'].prepaymentDetail.touched || submitted) && item['controls'].prepaymentDetail?.errors?.required">
                                                    Required
                                                </span>
                                            </td>
                                            <td>
                                                <div class="input-group datepicker ">
                                                    <input #invoiceDatePicker="ngbDatepicker" class="form-control"
                                                        formControlName="invoiceDate" ngbDatepicker
                                                        placeholder="yyyy-mm-dd" />
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary panel"
                                                            (click)="invoiceDatePicker.toggle()" type="button">
                                                            <fa-icon [icon]="faCalendar"></fa-icon>
                                                        </button>
                                                    </div>
                                                </div><span class="text-danger"
                                                    *ngIf="(item['controls'].invoiceDate.touched || submitted) && item['controls'].invoiceDate?.errors?.required">
                                                    Required
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="(item['controls'].invoiceDate.touched || submitted) && item['controls'].invoiceDate.errors?.dateInvalid">
                                                    Date not valid.
                                                </span>
                                            </td>
                                            <td><select class="form-control datepicker" formControlName="invoiceType">
                                                    <option value="Goods" selected="selected">Goods</option>
                                                    <option value="Goods & Services">Goods & Services</option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="(item['controls'].invoiceType.touched || submitted) && item['controls'].invoiceType?.errors?.required">
                                                    Required
                                                </span>
                                            </td>
                                            <td><input type="number" formControlName="invoiceAmount"
                                                    class="form-control inputForm1" />
                                                <span class="text-danger"
                                                    *ngIf="(item['controls'].invoiceAmount.touched || submitted) && item['controls'].invoiceAmount?.errors?.required">
                                                    Required
                                                </span>
                                            </td>
                                            <td><input type="number" formControlName="igst" class="form-control inputForm1" />
                                                <span class="text-danger"
                                                    *ngIf="(item['controls'].igst.touched || submitted) && item['controls'].igst?.errors?.required">
                                                    Required
                                                </span>
                                            </td>
                                            <td><input type="number" formControlName="cgst" class="form-control inputForm1" />
                                                <span class="text-danger"
                                                    *ngIf="(item['controls'].cgst.touched || submitted) && item['controls'].cgst?.errors?.required">
                                                    Required
                                                </span>
                                            </td>
                                            <td><input type="number" formControlName="sgst" class="form-control inputForm1" />
                                                <span class="text-danger"
                                                    *ngIf="(item['controls'].sgst.touched || submitted) && item['controls'].sgst?.errors?.required">
                                                    Required
                                                </span>
                                            </td>
                                            <td><input type="number" formControlName="tcsCharged"
                                                    class="form-control inputForm1" /> <span class="text-danger"
                                                    *ngIf="(item['controls'].tcsCharged.touched || submitted) && item['controls'].tcsCharged?.errors?.required">
                                                    Required
                                                </span>
                                            </td>
                                            <td><input type="number" formControlName="tcsDeposited"
                                                    class="form-control inputForm1" /> <span class="text-danger"
                                                    *ngIf="(item['controls'].tcsDeposited.touched || submitted) && item['controls'].tcsDeposited?.errors?.required">
                                                    Required
                                                </span>
                                              
                                            </td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-danger  btn-sm"
                                                    (click)="deleteItem(i)">
                                                    <fa-icon [icon]="faTrash"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colspan="6">&nbsp;</th>
                                        <th>{{invoiceAmountTotal}}</th>
                                        <th>{{igstTotal}}</th>
                                        <th>{{cgstTotal}}</th>
                                        <th>{{sgstTotal}}</th>
                                         <th>{{tcsChargedTotal}}</th>
                                        <th>{{tcsDepositedTotal}}</th>
                                        <th><button type="button" class="btn btn-primary  btn-sm" (click)="addItem()">
                                                <fa-icon [icon]="faPlus"></fa-icon>
                                            </button></th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-deck mb-3 text-center" *ngIf="addInvoice">
            <div class="card border-primary-top mb-4 box-shadow">
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col-md-12 text-left">
                            <label for="exampleFormControlFile1">Upload TCS certificate
                                for
                                the quarter</label>
                            <input type="file" #tcsCertificateInput class="form-control-file"
                                (change)="onFileChange($event)" accept=".pdf">
                            <span class="text-danger" *ngIf="fileTypeError">
                                Please upload PDF file only.
                            </span>
                            <span class="text-danger" *ngIf="fileRequired">
                                Please upload a TCS certificate.
                            </span>
                            <p class="font-weight-bold mt-2 mb-0" *ngIf="editMode">
                                <button type="button" class="btn btn-sm btn-outline-dark"
                                    (click)="downloadTcsCertificate()">
                                    <fa-icon [icon]="faFilePdf" *ngIf="!fileDownloading"></fa-icon>
                                    <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="fileDownloading"></fa-icon>
                                    TCS certificate: {{tcsCertificateDetails.fileName}}
                                </button>
                            </p>

                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <div class="form-row">
                        <div class="form-group col-md-12 text-center">    
                            <button type="button" class="btn btn-secondary" (click)="saveInvoice()" *ngIf="!loading">
                                <fa-icon [icon]="faSave"></fa-icon>&nbsp;<span>Save</span>
                            </button>&nbsp;
                      
                            <button type="submit" class="btn btn-success" *ngIf="!loading">
                                <fa-icon [icon]="faUpload"></fa-icon>&nbsp;<span>Publish</span>
                            </button>
                            <button class="btn btn-primary" type="button" *ngIf="loading">
                                <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>&nbsp;<span>Saving data. Please
                                    wait...</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
