import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppconfigService } from '../../shared/appconfig.service';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  apiBaseUrl: string | undefined;
  encrypted = null;




  constructor(private httpClient: HttpClient, private appconfigService: AppconfigService) {
    console.log("this.appconfigService.apiBaseUrl"+this.appconfigService.apiBaseUrl);
    this.apiBaseUrl = this.appconfigService.apiBaseUrl;
    //this.encrypted = this.encrypt1(sessionStorage.getItem('userId'));
  }
  // userUpdate(user: any) {
  //   return this.httpClient.post(this.apiBaseUrl + '/portal/xx45532', user);
  // }



 
}

