import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})

/**
 * This Service handles how the date is represented in input.
 */
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = "-";

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      } as NgbDateStruct;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string | null {
    return date ?
      date.year + this.DELIMITER + ('0' + date.month).slice(-2) + this.DELIMITER + ('0' + date.day).slice(-2) :
      '';
  }
}