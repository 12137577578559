import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@dell/dci-authentication';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        this.authService.Redirect_After_loginlogout = '';
        this.router.navigate(["/endsession"]);
      }
      const error = err.error.message || err.statusText;
      return throwError(error);
    }))
  }
}