import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from '@dell/dci-authentication';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(public authService: AuthService, private router: Router) { }

  ngOnInit() { }

  title = 'Tics';
}
