<div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
    <h1 class="display-4">TICS</h1>
    <p class="lead">Tax Information Collection System</p>
</div>
<div class="container">
    <div class="card-deck mb-3 text-center">
        <div class="card border-primary-top mb-4 py-3 box-shadow">
            <div class="card-body">
                <p class="option-icon">
                    <fa-icon [icon]="faFile" size="3x"></fa-icon>
                </p>
                <p class="lead">View Old Data</p>
                <button type="button" class="btn btn-primary btn-lg btn-block" [routerLink]="">Click
                    Here</button>
            </div>
        </div>
        <div class="card border-primary-top mb-4 py-3 box-shadow">
            <div class="card-body">
                <p class="option-icon">
                    <fa-icon [icon]="faUpload" size="3x"></fa-icon>
                </p>
                <p class="lead">Submit New Data</p>
                <button type="button" class="btn btn-primary btn-lg btn-block" [routerLink]="">Click
                    Here</button>
            </div>
        </div>
    </div>
</div>