import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faSave, faUpload, faTrash, faPlus, faCalendar, faCircleNotch, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { NgbDateStruct, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormArray, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UploadTemplateComponent } from '../../modal/upload-template/upload-template.component';
import { DuplicateWarningComponent } from '../../modal/duplicate-warning/duplicate-warning.component';
import { InvoiceService } from '../../services/invoice/invoice.service';
import { NgbDateCustomAdapter } from '../../shared/ngb-date-custom-adapter.service';
import { NgbDateCustomParserFormatter } from '../../shared/ngb-date-custom-parser-formatter.service';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateCustomAdapter }, { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }]
})
export class InvoiceComponent implements OnInit {

  faSave = faSave;
  faTrash = faTrash;
  faPlus = faPlus;
  faUpload = faUpload;
  faCalendar = faCalendar;
  faCircleNotch = faCircleNotch;
  faFilePdf = faFilePdf;
  templateUrl = "assets/Template.xlsx";
  templateFileName = "Template.xlsx";
  lineItemDate: NgbDateStruct | undefined;
  invoiceForm: FormGroup;
  lineItems: FormArray | undefined;
  gstinDisabled = false;
  submitted = false;
  quarters: any;
  addInvoice = false;
  loading = false;
  successMessage = "";
  editMode = false;
  fileDownloading = false;
  errorMessage: any = [];
  errorOccurred = false;
  dataSaved = false;
  duplicateChecking = false;
  fileTypeError = false;
  fileRequired = false;
  deleteLineItems: number[] = [];
  subscription: Subscription;
  invoiceAmountTotal: number = 0;
  cgstTotal: number = 0;
  sgstTotal: number = 0;
  igstTotal: number = 0;
  tcsChargedTotal: number = 0;
  tcsDepositedTotal: number = 0;
  tcsCertificate: any = null;
  tcsCertificateDetails: any = null;
  checkFileCont : any = null;
  @ViewChild('tcsCertificateInput') tcsCertificateInput: ElementRef;

  //Number Validators
  private numberValidator = [
    Validators.pattern(/^\d{1,8}(\.\d{1,2})?$/),
    Validators.required
  ];

  //Gstin Validators
  private gstinValidators = [
    Validators.pattern(/^[0-9]{2}[a-zA-Z0-9]{5}[0-9]{4}[a-zA-Z0-9]{1}[0-9]{1}([a-zA-Z0-9]{2}$)/),
    Validators.required
  ];

  //Custom Date Validator
  isValidDate(control: AbstractControl): ValidationErrors {
    if (control.parent != undefined) {
      let value = control.parent.get("invoiceDate").value;
      if (value === "" || value === null) {
        return null;
      }
      // date pattern
      var pattern = /(19|20)\d{2}-(0\d{1}|1[0-2])-([0-2]\d{1}|3[0-1])/
      var m = value.match(pattern);

      if (!m)
        return { dateInvalid: true };
      var d = new Date(value);
      // Now let's ensure that the date is not out of index
      if (d.getMonth() + 1 == parseInt(m[1], 10) && d.getDate() == parseInt(m[2], 10)) {
        return { dateInvalid: true };
      }
    }
    return null;
  }

  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private invoiceService: InvoiceService) {
    this.invoiceForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      pan: ['', [Validators.required, Validators.pattern('^[a-zA-Z]{5}[0-9]{4}([a-zA-Z]{1}$)')]],
      tan: ['', [Validators.required, Validators.pattern('^[a-zA-Z]{4}[0-9]{5}([a-zA-Z]{1}$)')]],
      gstin: ['', this.gstinValidators],
      unregGstin: false,
      quarterId: ['', Validators.required],
      status: ['S'],
      lineItems: this.formBuilder.array([this.createItem()]),
    });
  }

  ngOnInit(): void {
    this.invoiceForm?.get("unregGstin")?.valueChanges.subscribe(selectedValue => {
      this.invoiceForm?.get('gstin')?.setValidators(this.gstinValidators.concat(this.conditionalRequired(selectedValue)))
    })
    this.getQuarters();
    this.subscription = this.invoiceForm.get('lineItems').valueChanges.subscribe((data: any) => {
      this.invoiceAmountTotal = Number(parseFloat(data.reduce((a: number, b: { invoiceAmount: string | number; }) => a + + b.invoiceAmount, 0)).toFixed(2))
      this.cgstTotal =  Number(parseFloat(data.reduce((a: number, b: { cgst: string | number; }) => a + + b.cgst, 0)).toFixed(2))
      this.sgstTotal =  Number(parseFloat(data.reduce((a: number, b: { sgst: string | number; }) => a + + b.sgst, 0)).toFixed(2))
      this.igstTotal =  Number(parseFloat(data.reduce((a: number, b: { igst: string | number; }) => a + + b.igst, 0)).toFixed(2))
      this.tcsChargedTotal = Number(parseFloat(data.reduce((a: number, b: { tcsCharged: string | number; }) => a + + b.tcsCharged, 0)).toFixed(2))
      this.tcsDepositedTotal = Number(parseFloat(data.reduce((a: number, b: { tcsDeposited: string | number; }) => a + + b.tcsDeposited, 0)).toFixed(2))
    })
  }

  //Gstin Conditional Validator
  conditionalRequired(condition: any) {
    return !condition ? Validators.required : Validators.nullValidator;
  }

  //Create Form Group
  createItem(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      vendorId: [''],
      tan: ['', [Validators.required,Validators.pattern('^[a-zA-Z]{4}[0-9]{5}([a-zA-Z]{1}$)')]],
      invoiceNumber: ['0', Validators.required],
      cnDnNumber: [0, this.numberValidator],
      prepaymentDetail: [0, Validators.required],
      invoiceDate: ['', [Validators.required, this.isValidDate]],
      invoiceType: ['', Validators.required],
      invoiceAmount: [0, this.numberValidator],
      cgst: [0, this.numberValidator],
      sgst: [0, this.numberValidator],
      igst: [0, this.numberValidator],
      tcsCharged: [0, this.numberValidator],
      tcsDeposited: [0, this.numberValidator],
    });
  }

  //Get Invoice Form Control
  get invoiceFormControl() {
    return this.invoiceForm.controls;
  }

  //Get Line Items
  getLineItems() {
    return (this.invoiceForm.get('lineItems') as FormArray).controls;
  }

  //Add Line Item
  addItem(): void {
    this.lineItems = this.invoiceForm.get('lineItems') as FormArray;
    this.lineItems.push(this.createItem());
  }

  //Delete Line Item
  deleteItem(index: number) {
    this.lineItems = this.invoiceForm.get('lineItems') as FormArray;
    if (this.lineItems.length == 1) {
      return false;
    } else {
      if (this.editMode) {
        let lineItemId = this.lineItems.at(index).get("id").value;
        console.log(lineItemId);
        if (lineItemId > 0) {
          this.deleteLineItems.push(lineItemId);
        }
      }

      this.lineItems.removeAt(index);
      return true;
    }
  }


  saveInvoice() {
    this.invoiceForm.get('status').setValue('S');
    this.successMessage = "TCS data saved successfully for selected quarter.";
    if (this.editMode) {
      this.submitInvoiceEdit();
    }
    else {
      this.submitInvoice();
    }
  }

  //Publish Invoice Data
  publishInvoice() {
    this.successMessage = "TCS data published successfully for selected quarter.";
    this.invoiceForm.get('status').setValue('P');
    if (this.editMode) {
      this.submitInvoiceEdit();
    }
    else {
      this.submitInvoice();
    }
  }

  //Submit Invoice Data - in Edit Mode
  async submitInvoiceEdit() {
    this.submitted = true;
    if (this.tcsCertificate === null) {
      this.fileRequired = true;
    }
    if (this.tcsCertificateDetails != null) {
      this.fileRequired = false;
    }

      this.checkFileCont = true;

    // added for checking content of pdf so no malware or exe can be uploaded.
 
    //Check if form is valied
   if (this.invoiceForm.valid && !this.fileRequired && this.checkFileCont) {
     
      this.loading = true;
      this.errorMessage = [];
      this.errorOccurred = this.dataSaved = false;
      let vendorData = this.invoiceForm.value;
      //Seperate line items from vendor data
      let lineItems = vendorData['lineItems'];
      delete vendorData['lineItems'];
      //Set Gstin to UNREG if checkbox is cehcked
      if (vendorData['unregGstin']) {
        vendorData['gstin'] = "UNREG";
      }
      //Set created by, modified by and status
     
    
      vendorData['modifiedDate']= new Date();
      //Split LineItems
      let addLineItems: any[] = [];
      let updateLineItems: any[] = [];
      for (const item of lineItems) {
        if (item['id'] > 0) {
          updateLineItems.push(item);
        } else {
          item['vendorId'] = vendorData.id;
          addLineItems.push(item);
        }
      }
      //Submit Vendor Data
      try {
        
        await this.invoiceService.updateVendor(vendorData);
        if (vendorData.id) {
          try {
            await this.invoiceService.addInvoice(addLineItems);
          } catch (error) {
            this.errorOccurred = true;
            this.errorMessage.push("Error while saving transaction details.");
          }
          try {
            await this.invoiceService.updateInvoice(updateLineItems);
          } catch (error) {
            this.errorOccurred = true;
            this.errorMessage.push("Error while saving transaction details.");
          }
          if (this.deleteLineItems.length > 0) {
            try {
              await this.invoiceService.deleteInvoice(this.deleteLineItems);
            } catch (error) {
              this.errorOccurred = true;
              this.errorMessage.push("Error while saving transaction details.");
            }
          }
          if (this.tcsCertificate != null) {
            try {
              await this.invoiceService.updateTcsCertificate(this.tcsCertificate, this.tcsCertificateDetails.id, vendorData.id, vendorData.quarterId);
            } catch (error) {
              this.errorOccurred = true;
              //this.errorMessage.push("Error while updating TCS certificate.");
            }
          }
        }
      }
      catch {
        this.errorOccurred = true;
        this.errorMessage.push("Error while saving vendor data.");
      }
      this.resetForm();
      let element = document.getElementById("alert-section");
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
      this.loading = this.submitted = this.addInvoice = this.editMode = false;
      this.dataSaved = true;
    }
  }


  //Submit Invoice Data
  async submitInvoice() {
    this.submitted = true;
    if (this.tcsCertificate === null) {
      this.fileRequired = true;
    }
    if (this.tcsCertificateDetails != null) {
      this.fileRequired = false;
    }
 
   this.checkFileCont = true;
// added for checking content of pdf so no malware or exe can be uploaded.
   

   //Check if form is valid
    if (this.invoiceForm.valid && !this.fileRequired &&  this.checkFileCont ) {
      this.loading = true;
      this.errorMessage = [];
      this.errorOccurred = this.dataSaved = false;
      let vendorData = this.invoiceForm.value;
      //Seperate line items from vendor data
      let lineItems = vendorData['lineItems'];
      delete vendorData['lineItems'];
      //Set Gstin to UNREG if checkbox is cehcked
      if (vendorData['unregGstin']) {
        vendorData['gstin'] = "UNREG";
      }
      //Set created by, modified by and status
      
      vendorData['modifiedDate']= new Date();

      //Submit Vendor Data
      let createVendor: any;
      try {
      
        createVendor = await this.invoiceService.addVendor(vendorData);
        if (createVendor.id) {
          try {
            //Update Vendor ID
            let invoices: any[] = [];
            for (const item of lineItems) {
              item['vendorId'] = createVendor.id;
              invoices.push(item);
            }
            await this.invoiceService.addInvoice(invoices);
          } catch {
            this.errorOccurred = true;
            this.errorMessage.push("Error while saving transaction details.");
          }
          try {
            await this.invoiceService.uploadTcsCertificate(this.tcsCertificate, createVendor.id, createVendor.quarterId);
          } catch {
              this.errorOccurred = true;
              //this.errorMessage.push("Error while uploading file.");
          }
        } else {
          this.errorOccurred = true;
          this.errorMessage.push("Error while saving vendor data.");
        }
      }
      catch {
        this.errorOccurred = true;
        this.errorMessage.push("Error while saving vendor data.");
      }
      this.resetForm();
      let element = document.getElementById("alert-section");
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
      this.loading = this.submitted = this.addInvoice = false;
      this.dataSaved = true;
}    
    
  }

  //Reset form data
  resetForm() {
    this.invoiceForm.reset();
  }

  //Upload Excel Template
  openUploadTemplate() {
    const modalRef = this.modalService.open(UploadTemplateComponent, { size: 'lg' });
    modalRef.componentInstance.passExcelData.subscribe((receivedEntry: any) => {
      this.lineItems = this.invoiceForm.get('lineItems') as FormArray;

      let firstValue = this.lineItems.at(0);
      if (firstValue.get('tan')?.value === "") {
        this.lineItems.removeAt(0);
      }

      for (let i = 1; i < receivedEntry.data.length; i++) {
        this.lineItems.push(this.formBuilder.group({
          id: [''],
          tan: [receivedEntry.data[i][0], [Validators.required,Validators.pattern('^[a-zA-Z]{4}[0-9]{5}([a-zA-Z]{1}$)')]],
          invoiceNumber: [receivedEntry.data[i][1], Validators.required],
          cnDnNumber: [receivedEntry.data[i][2], this.numberValidator],
          prepaymentDetail: [receivedEntry.data[i][3], Validators.required],
          invoiceDate: [receivedEntry.data[i][4], [Validators.required, this.isValidDate]],
          invoiceType: [receivedEntry.data[i][5], Validators.required],
          invoiceAmount: [receivedEntry.data[i][6], this.numberValidator],
          igst: [receivedEntry.data[i][7], this.numberValidator],
          sgst: [receivedEntry.data[i][8], this.numberValidator],
          cgst: [receivedEntry.data[i][9], this.numberValidator],
          tcsCharged: [receivedEntry.data[i][10], this.numberValidator],
          tcsDeposited: [receivedEntry.data[i][11], this.numberValidator],
        }));
      }
    })
  }

  //Change GSTIN value based on cehckbox selection 
  changeGstin(event: any) {
    if (event.target.checked) {
      this.invoiceForm.controls['gstin'].reset();
      this.invoiceForm.controls['gstin'].disable();
    }
    else {
      this.invoiceForm.controls['gstin'].enable();
    }
  }

  //Get list of quarters
  getQuarters() {
    this.invoiceService.getQuarters().subscribe((result) => {
      this.quarters = result;
    });
  }

  //Check if invoice previously submitted against quarter ID and user ID
  async checkForDuplicate() {
    let quarterId = this.invoiceForm.get('quarterId').value;
    this.lineItems = this.invoiceForm.get('lineItems') as FormArray;
    this.duplicateChecking = true;
    this.errorOccurred = this.dataSaved  = this.editMode = this.addInvoice = false;
    this.deleteLineItems = this.errorMessage = [];
    let allPublished = false;
    let allSaved = true;

    if (quarterId > 0) {
      this.duplicateChecking = true;
      this.errorOccurred = this.dataSaved = this.editMode = this.addInvoice = false;
      let invoiceCount: any;
      let vendorData: any;
      let invoiceData: any;
      this.tcsCertificateDetails = null;
      try {
        let userId = sessionStorage.getItem('userId');
        vendorData = await this.invoiceService.checkDuplicate(quarterId, userId);

        if(vendorData != null && vendorData.length > 0 ){
         
          for (let k = 0; k < vendorData.length; k++) {

            if (vendorData[k] != null && vendorData[k].id != null && vendorData[k].status === "P") {
              allPublished = true;
              continue;
            } else if (vendorData[k] != null && vendorData[k].id != null && vendorData[k].status === "S") {
              allSaved = false;
              try {
                this.invoiceForm.patchValue({
                  id: vendorData[k].id,
                  name: vendorData[k].name,
                  pan: vendorData[k].pan,
                  tan: vendorData[k].tan,
                  gstin: vendorData[k].gstin,
                  status: vendorData[k].status,
                  createdBy: vendorData[k].createdBy
                });
                if (vendorData[k].gstin === "UNREG") {
                  this.invoiceForm.get("unregGstin").setValue(true);
                  this.invoiceForm.controls['gstin'].reset();
                  this.invoiceForm.controls['gstin'].disable();
                }
                  invoiceData = await this.invoiceService.getInvoices(vendorData[k].id);
                  this.lineItems.clear();
                  for (let i = 0; i < invoiceData.length; i++) {
                    this.lineItems.push(this.formBuilder.group({
                      id: [invoiceData[i].id],
                      vendorId: [vendorData[k].id],
                      tan: [invoiceData[i].tan, Validators.required],
                      invoiceNumber: [invoiceData[i].invoiceNumber, Validators.required],
                      invoiceDate: [invoiceData[i].invoiceDate, Validators.required],
                      invoiceType: [invoiceData[i].invoiceType, Validators.required],
                      invoiceAmount: [invoiceData[i].invoiceAmount, this.numberValidator],
                      cnDnNumber: [invoiceData[i].cnDnNumber, this.numberValidator],
                      prepaymentDetail: [invoiceData[i].prepaymentDetail, this.numberValidator],
                      cgst: [invoiceData[i].cgst, this.numberValidator],
                      sgst: [invoiceData[i].sgst, this.numberValidator],
                      igst: [invoiceData[i].igst, this.numberValidator],
                      tcsCharged: [invoiceData[i].tcsCharged, this.numberValidator],
                      tcsDeposited: [invoiceData[i].tcsDeposited, this.numberValidator]
                     }));
                  }
                } catch {
                  this.errorOccurred = true;
                  this.errorMessage.push("Error while fetching invoice details.");
                }
      
                try {
                  this.tcsCertificateDetails = await this.invoiceService.getTcsCertificate(vendorData[k].id);
                  this.fileRequired = false;
                }
                catch {
                  this.errorOccurred = true;
                  this.errorMessage.push("Error while fetching TCS Certifcate.");
                }
                if (!this.errorOccurred) {
                  this.editMode = true;
                }
              }
              else {
               
              this.lineItems.clear();
              this.addInvoice = true;
              this.invoiceForm.patchValue({
                name: '',
                pan: '',
                tan: ''
              });
              this.invoiceForm.get("unregGstin").setValue(false);
              this.invoiceForm.controls['gstin'].reset();
              this.invoiceForm.controls['gstin'].enable();
             }
  
  
  
          }
  
          if(allPublished && allSaved){
            
            if(this.lineItems.length >= 1){
              this.lineItems.clear();
            }
          
            const modalRef = this.modalService.open(DuplicateWarningComponent);
            modalRef.componentInstance.quarterId = quarterId;
            modalRef.componentInstance.passUserSelection.subscribe((receivedEntry: any) => {
              if (receivedEntry.data === "ok") {
              this.addInvoice = true;
              } else {
                this.addInvoice = false;
              }
            });
            this.addItem();
           
           
            
            this.invoiceForm.patchValue({
                name: '',
                pan: '',
                tan: ''
              });
              this.invoiceForm.get("unregGstin").setValue(false);
              this.invoiceForm.controls['gstin'].reset();
              this.invoiceForm.controls['gstin'].enable();

            
              
          }
  

        }else{
        
          if(this.lineItems.length >= 1){
            this.lineItems.clear();
          }
          this.addItem()
          this.invoiceForm.patchValue({
              name: '',
              pan: '',
              tan: ''
            });
            this.invoiceForm.get("unregGstin").setValue(false);
            this.invoiceForm.controls['gstin'].reset();
            this.invoiceForm.controls['gstin'].enable();
          
        }

               
       
      } catch {
        this.errorOccurred = true;
        this.errorMessage.push("Error while checking for duplicate data.");
      }
      if (!this.errorOccurred) {
       
        this.addInvoice = true;
      }

      this.duplicateChecking = false;
    } else {
     
      this.addInvoice = false;
      this.lineItems.clear();
      this.addInvoice = true;
      this.invoiceForm.patchValue({
        name: '',
        pan: '',
        tan: ''
      });
      this.invoiceForm.get("unregGstin").setValue(false);
      this.invoiceForm.controls['gstin'].reset();
      this.invoiceForm.controls['gstin'].enable();
    }
    this.duplicateChecking = false;
  }

  async onFileChange(event: any) {
    this.fileTypeError = false;
    this.fileRequired = false
    this.tcsCertificate = null;
    let tcsCertificateFile = event.target.files[0];
    if (tcsCertificateFile !== undefined && tcsCertificateFile.type === "application/pdf") {
      this.tcsCertificate = tcsCertificateFile;
    } else {
      this.tcsCertificateInput.nativeElement.value = "";
      this.fileTypeError = true;
    }
  }

  downloadTcsCertificate() {
    this.fileDownloading = true;
    this.invoiceService.downloadTcsCertificate(this.tcsCertificateDetails.id).subscribe((response: any) => {
      let blob: any = new Blob([response], { type: 'application/pdf' });
      saveAs(blob, this.tcsCertificateDetails.fileName + '.pdf');
      this.fileDownloading = false;
    }, () => { this.fileDownloading = false; });
  }


}
