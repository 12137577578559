import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppconfigService } from '../../shared/appconfig.service';

@Injectable({
  providedIn: 'root'
})



export class ReportService {
  apiBaseUrl: string | undefined;
  encrypted = null;



  constructor(private httpClient: HttpClient, private appconfigService: AppconfigService) {
    this.apiBaseUrl = this.appconfigService.apiBaseUrl
    //this.encrypted = this.encrypt1(sessionStorage.getItem('userId'));
  }

  //Get Quarters from backend
  getQuarters() {
    return this.httpClient.post(this.apiBaseUrl + "/portal/magicQucik",'');
  }

  getVendorsByUserId(userId: any) {
    return this.httpClient.post(this.apiBaseUrl + '/portal/downloadXX','');
  }


  getVendorsByQuarterId(userId: any, quarterId: any) {
    return this.httpClient.post(this.apiBaseUrl + '/portal/downloadXX?quarterId=' + btoa(quarterId),'');
  }

  getInvoices(vendorId: any) {
    return this.httpClient.post(this.apiBaseUrl + '/portal/checkXXX$$$12/xxWWW?xxId=' + btoa(vendorId),'');
  }

  getQuarterById(xx$$: any) {
    return this.httpClient.post(this.apiBaseUrl + '/portal/magicQucik/' + btoa(xx$$),'');
  }

  downloadVendorTcsCertificate(vendorId: any) {
    return this.httpClient.post(this.apiBaseUrl + '/portal/welding/formMapping?xyz=' + btoa(vendorId), { responseType: 'blob' });
  }
}
