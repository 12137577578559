import { Component, OnInit } from '@angular/core';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Router } from "@angular/router";
import { AuthService } from '@dell/dci-authentication';
import { HeaderService } from '../../services/header/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  imageSrc = '../assets/images/Dell_Logo_Wht_rgb.png'
  imageAlt = 'Logo'
  faPowerOff = faPowerOff
  profile: { [key: string]: string } = {};
  claims: any;
  public navbarCollapsed = true;

  constructor(public authService: AuthService, private router: Router, private headerService: HeaderService) { }

  ngOnInit() {
    let token: string = sessionStorage.getItem('id_token')!;
    const user = this.authService.getTokenClaims(token);
    let obj: { [key: string]: string } = {};
    if (user != null) {
      Object.keys(user.payload).map(function (key) {
        if (key.includes("claim")) {
          obj[key.replace("http://www.dell.com/identity/claims/", "")] = user.payload[key];
        }
        return obj;
      });
      let userObj: { [key: string]: string } = {};
      userObj['firstname'] = obj['profile/firstname'];
      userObj['lastname'] = obj['profile/lastname'];
      userObj['email'] = obj['profile/username'];
      userObj['type'] = "V";
      this.profile = userObj;
/*       this.headerService.userUpdate(userObj).subscribe((result: any) => {
        sessionStorage.setItem('userId', result['id']);
      }); */
    }
  }

  dciLogout() {
    this.authService.Redirect_After_loginlogout = '';
   // sessionStorage.removeItem('userId');
    //sessionStorage.clear;
    this.router.navigate(["/endsession"]);

  }

}
