<header>
    <nav class="navbar navbar-expand-md navbar-dark navbar-custom box-shadow">
        <a routerLink="home">
            <img [src]="imageSrc" [alt]="imageAlt" width="40" height="40" /></a>
        <span class="app-title navbar-brand">TICS</span>
        <button class="navbar-toggler" type="button" (click)="navbarCollapsed = !navbarCollapsed" [attr.aria-expanded]="!navbarCollapsed" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="navbarCollapsed">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <a class="nav-link" routerLink="home">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="">Invoice</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="">Reports</a>
                </li>
            </ul>

            <span class="nav-username">{{ profile['firstname'] }}&nbsp;{{ profile['lastname'] }}</span>
            <a [routerLink]="" (click)="dciLogout()"  class="btn btn-outline-light">
                <fa-icon [icon]="faPowerOff"></fa-icon>
            </a>
        </div>
    </nav>
</header>