<div class="pricing-header px-3 pt-3 mx-auto text-center">
    <h3 class="display-5">TICS</h3>
    <p class="lead">Tax Information Collection System</p>
</div>

<div class="container-fluid">
    <div class="card-deck mb-3 text-center">
        <div class="card border-primary-top  mb-4 box-shadow">
            <div class="card-header">
                <h5 class="my-0 font-weight-normal">Old TCS Certificates</h5>
            </div>
            <div class="card-body">
                <form>
                    <div class="form-group form-inline">
                        Search: <input type="text" [(ngModel)]="term" name="term" class="form-control ml-2 mr-2">
                        <fa-icon [icon]="faCircleNotch" class="ml-2" [spin]="true" *ngIf="dataLoading"></fa-icon>
                    </div>
                </form>
                <ngb-alert [dismissible]="false" type="warning" *ngIf="!vendors?.length && !dataLoading">
                    No data found.
                </ngb-alert>

                <table class="table table-striped table-hover" aria-label="Tcs Reports" *ngIf="vendors?.length">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col">Quarter</th>
                            <th scope="col">Name</th>
                            <th scope="col">PAN</th>
                            <th scope="col">TAN</th>
                            <th scope="col">GSTIN</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of vendors |filter:term">
                            <td>{{item.quarter}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.pan}}</td>
                            <td>{{item.tan}}</td>
                            <td>{{item.gstin}}</td>
                            <td><button type="button" class="btn btn-sm btn-primary" (click)="viewInvoice(item)">
                                    <fa-icon [icon]="faEye"></fa-icon> View Invoice
                                </button>&nbsp;
                                <button type="button" class="btn btn-sm btn-danger"
                                    (click)="downloadVendorTcsCertificate(item.id, item.name)">
                                    <fa-icon [icon]="faFilePdf" *ngIf="itemDownloading != item.id"></fa-icon>
                                    <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="itemDownloading == item.id">
                                    </fa-icon>
                                    Pdf
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>